@import "../base.scss";
@import "./variables.scss";

.pageContainer {
  display: block;
  width: 100%;
  background-color: #fff;
}

.mobileMenu {
  background: $researchRoomGradient;
}

.largeLogo {
  width: 232px;
  height: 37px;

  @include mqMaxWidth(568px) {
    left: -76px;
    width: 145px;

    svg {
      width: 218px;

    }
  }
}

.bookDemoButton {
  margin-left: 10px;

  @include mqMaxWidth(568px) {
    display: block;
    margin-right: 0;
  }
}
